<template>
  <v-container class="fill-height justify-center">
    <v-container>
      <v-row justify="center">
        <v-img max-height="150" max-width="300" src="../assets/LicenseServer_TitleText_white.png"></v-img>
      </v-row>
      <v-expand-transition>
        <v-row v-if="showLogin" justify="center">
          <v-col>
            <v-row justify="center">
              <mex-p class="mt-15" alignment="center" content="Please log in using the credentials provided to you. " />
            </v-row>
            <v-row justify="center">
              <validation-observer v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(validateFiReLoginForm)">
                  <mex-card :title="title" color="primary" icon="mdi-account-plus" light max-width="100%" width="400">
                    <validation-provider v-slot="{ errors }" name="username" rules="required">
                      <v-text-field
                        v-model="username"
                        :error-messages="errors"
                        class="mt-10"
                        color="secondary"
                        label="Username"
                        prepend-icon="mdi-account"
                      />
                    </validation-provider>
                    <validation-provider v-slot="{ errors }" name="password" rules="required">
                      <v-text-field
                        v-model="password"
                        :error-messages="errors"
                        class="mt-10"
                        color="secondary"
                        label="Password"
                        prepend-icon="mdi-form-textbox-password"
                        type="password"
                      />
                    </validation-provider>
                    <v-btn :loading="loginLoading" type="submit"> Login</v-btn>
                  </mex-card>
                </form>
              </validation-observer>
            </v-row>
          </v-col>
        </v-row>
      </v-expand-transition>

      <v-expand-transition>
        <v-row v-if="showUpdatePassword" justify="center">
          <v-col>
            <v-row justify="center">
              <mex-p class="mt-15" alignment="center" content="To complete your registration, please define a new password." />
            </v-row>
            <v-row justify="center">
              <mex-p alignment="center" :content="passwordRequirements" />
            </v-row>
            <v-row justify="center">
              <validation-observer v-if="showUpdatePassword" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(validateFiRePasswordUpdateForm)">
                  <mex-card :title="title" color="primary" icon="mdi-key-plus" light max-width="100%" width="400">
                    <validation-provider v-slot="{ errors }" name="new password" rules="required|password">
                      <v-text-field
                        v-model="newPassword"
                        :error-messages="errors"
                        class="mt-10"
                        color="secondary"
                        label="New password"
                        prepend-icon="mdi-form-textbox-password"
                        type="password"
                      />
                    </validation-provider>
                    <validation-provider v-slot="{ errors }" name="new password confirmation" rules="required|passwordConfirm:@new password">
                      <v-text-field
                        v-model="newPasswordConfirmation"
                        :error-messages="errors"
                        class="mt-10"
                        color="secondary"
                        label="Confirm new password"
                        prepend-icon="mdi-form-textbox-password"
                        type="password"
                      />
                    </validation-provider>
                    <v-btn :loading="passwordUpdateLoading" type="submit"> Update password</v-btn>
                  </mex-card>
                </form>
              </validation-observer>
            </v-row>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-expand-transition>
        <v-row v-if="show2FakInit" justify="center">
          <v-col>
            <v-row justify="center">
              <mex-p
                class="mt-15"
                alignment="center"
                content="To ensure the best possible security, each login is subject to a two-factor authentication. To do this, you must scan this QR code with the Google Authenticator app after you have installed it on your smartphone."
              />
            </v-row>
            <v-row justify="center">
              <validation-observer v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(validateFiRe2FAForm)">
                  <mex-card :title="title" color="primary" icon="mdi-two-factor-authentication" light max-width="100%" width="400">
                    <v-row justify="center">
                      <mex-p alignment="center" content="Scan this with the Google Authenticator App" />
                    </v-row>
                    <v-row justify="center">
                      <v-img :src="qrCode" max-height="300" max-width="300" />
                    </v-row>
                    <v-row class="mb-2" justify="center">
                      <validation-provider v-slot="{ errors }" name="two factor authentication token" rules="required|numeric">
                        <v-text-field
                          v-model="twoFakToken"
                          :error-messages="errors"
                          class="mt-10"
                          color="secondary"
                          label="Token"
                          prepend-icon="mdi-two-factor-authentication"
                        />
                      </validation-provider>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="auto">
                        <v-btn :loading="twoFakVerifyLoading" type="submit">Verify Token</v-btn>
                      </v-col>
                    </v-row>
                  </mex-card>
                </form>
              </validation-observer>
            </v-row>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-expand-transition>
        <v-row v-if="show2FakRecoveryCodes" justify="center">
          <v-col>
            <v-row justify="center">
              <mex-p alignment="center" content="These are your individual recovery codes." />
            </v-row>
            <v-row justify="center">
              <mex-p
                alignment="center"
                content="They are used to access your account in the event you can't receive a two-factor authentication
              token."
              />
            </v-row>
            <v-row justify="center">
              <mex-sheet color="error">
                <mex-p
                  alignment="center"
                  class="mb-0"
                  content="Store these codes as securely and carefully as you would store your passwords."
                  fontWeight="bold-italic"
                />
              </mex-sheet>
            </v-row>
            <v-row justify="center">
              <mex-card :title="title" color="primary" icon="mdi-exit-run" light max-width="100%" width="400">
                <v-row>
                  <v-col cols="12">
                    <mex-sheet color="white" rounded>
                      <mex-p v-for="(code, i) in recoveryCodes" :key="i" :content="code" alignment="center" />
                    </mex-sheet>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <mex-btn
                      badgeContent="!"
                      badgeValue="1"
                      color="error"
                      content="Copy"
                      icon="mdi-clipboard-text-outline"
                      @click="copyRecoveryCodes"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-btn @click="goToHome"> Enter</v-btn>
                  </v-col>
                </v-row>
              </mex-card>
            </v-row>
          </v-col>
        </v-row>
      </v-expand-transition>
      <!-- <v-expand-transition>
        <v-row justify="center" v-if="showUpdateUserInfo">
          <v-col>
            <v-row justify="center">
              <mex-p alignment="center" content="Add or adjust the following user-related information as appropriate." />
            </v-row>
            <v-row justify="center">
              <mex-card icon="mdi-badge-account-outline" :title="title" color="primary" light max-width="100%" width="400">
                <v-text-field color="primaryAccent"color="secondary" label="Firstname" class="mt-10" v-model="firstName" />
                <v-text-field color="primaryAccent"color="secondary" label="Lastname" class="mt-10" v-model="lastName" />
                <v-btn @click="fiReUserInfoUpdate" :loading="userInfoUpdateLoading"> Update User info</v-btn>
              </mex-card>
            </v-row>
          </v-col>
        </v-row>
      </v-expand-transition> -->
    </v-container>
    <mex-dialog
      :showDialog="show2FakLoginFail"
      dialogAcceptButtonTitle="Okay"
      dialogTitle="Authentication failed!"
      @accept="show2FakLoginFail = false"
    >
      <template v-slot:dialog-content>
        <v-row justify="center">
          <mex-p :content="getTryCountText" />
        </v-row>
        <v-row justify="center">
          <mex-p content="Select a token that has the highest possible maturity at the moment." />
        </v-row>
        <v-row justify="center">
          <v-img max-width="500px" src="../assets/GA_Hint.png" />
        </v-row>
      </template>
    </mex-dialog>
    <!-- This is needed to copy the treeview path to the client clipboard -->
    <input id="hiddenShortcutContent" :value="getHiddenShortcutContent" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SystemUsersService from '../services/systemUsers.service';

export default {
  name: 'FinishRegistration',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      // visualization
      showLogin: false,
      showUpdatePassword: false,
      showUpdateUserInfo: false,
      show2FakInit: false,
      show2FakLoginFail: false,
      show2FakRecoveryCodes: false,
      loginLoading: false,
      passwordUpdateLoading: false,
      userInfoUpdateLoading: false,
      twoFakVerifyLoading: false,
      passwordRequirements:
        'The new password must at least contain an uppercase letter, a lowercase letter, a number, and a special character (! " # $ % & \' ( ) * + , - . : ; < = > ? @ a ^ _ ` { | } ~) with a min length of 8 characters.',
      // registration data
      username: '',
      password: '',
      newPassword: '',
      newPasswordConfirmation: '',
      firstName: '',
      lastName: '',
      // 2FA
      qrCode: '',
      twoFakToken: '',
      tryCount: 0,
      tryCountMax: 3,
      recoveryCodes: null,
      hiddenShortcutContent: '',
    };
  },
  computed: {
    ...mapGetters('theme', ['getDarkModeSetting', 'getActTheme']),
    getTryCountText() {
      return `The two factor authentication failed for the ${this.tryCount} of ${this.tryCountMax} times. Please try again and rescan the QR Code with the Google Authenticator App if necessary.`;
    },
    getHiddenShortcutContent() {
      return this.hiddenShortcutContent;
    },
  },
  created() {
    this.$store.commit('sysAuth/logout');
    this.$store.commit('sysAuth/registrationProcessActive');
    SystemUsersService.systemUserLogout();
    this.$vuetify.theme.themes = this.getActTheme;
    this.$vuetify.theme.dark = this.getDarkModeSetting;
    this.decodeQueryParams();
    this.username = this.$route.query.username;
    this.firstName = this.$route.query.firstName;
    this.lastName = this.$route.query.lastName;
    this.showLogin = true;
  },
  methods: {
    decodeQueryParams() {
      Object.keys(this.$route.query).forEach((key) => {
        let data = key;
        let buff = Buffer.from(data, 'base64');
        const decodedKey = buff.toString('ascii');
        data = this.$route.query[key];
        buff = Buffer.from(data, 'base64');
        this.$route.query[decodedKey] = buff.toString('ascii');
      });
    },

    goToHome() {
      this.$router.push('/');
    },

    // v ~~Login Functions ~~v
    validateFiReLoginForm() {
      this.fiReLogin();
    },
    validateFiRePasswordUpdateForm() {
      this.fiRePasswordUpdate();
    },
    validateFiRe2FAForm() {
      this.systemUserAuthenticate();
    },
    fiReLogin() {
      this.loginLoading = true;
      SystemUsersService.finishRegistraitonAuth(this.username, this.password)
        .then(() => {
          this.showLogin = false;
          this.showUpdatePassword = true;
          this.loginLoading = false;
        })
        .catch((err) => {
          this.loginLoading = false;
          if (err.response.status === 403) {
            this.$router.push('FinishRegistrationExpired');
          } else {
            this.$toast.error(err.response.data);
          }
        });
    },

    fiRePasswordUpdate() {
      this.passwordUpdateLoading = true;
      SystemUsersService.updatePassword(this.username, this.password, this.newPassword)
        .then((response) => {
          this.qrCode = response.data.otpUri;
          this.recoveryCodes = JSON.parse(response.data.twoFactorRecoveryCodes);
          this.$toast.success('Password successfully updated');
          this.passwordUpdateLoading = false;
          this.showUpdatePassword = false;
          this.showUpdateUserInfo = true;
          this.show2FakInit = true;
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
        });
    },
    fiReUserInfoUpdate() {
      this.userInfoUpdateLoading = true;
      SystemUsersService.updateUserInfo(this.username, this.firstName, this.lastName, this.newPassword)
        .then((response) => {
          this.$toast.success(response.data);
          this.userInfoUpdateLoading = false;
          this.showUpdateUserInfo = false;
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
        });
    },
    systemUserAuthenticate() {
      this.twoFakVerifyLoading = true;
      SystemUsersService.systemUserAuthenticate(this.username, this.newPassword, this.twoFakToken)
        .then(() => {
          this.$store.commit('sysAuth/registrationProcessInactive');
          // set the json web token in store and go to main page
          this.$toast.success('2FA successfully enabled!');
          this.twoFakVerifyLoading = false;
          this.show2FakInit = false;
          this.show2FakRecoveryCodes = true;
        })
        .catch((err) => {
          this.twoFakVerifyLoading = false;
          if (err.response.status === 401) {
            if (this.tryCount < this.tryCountMax) {
              this.tryCount++;
              this.show2FakLoginFail = true;
            } else {
              this.$router.push('FinishRegistrationExpired');
            }
          }
        });
    },
    // ------- COPY RECOVERY CODES -----
    /**
     * @param fdff fdfdf
     */
    copyRecoveryCodes() {
      this.hiddenShortcutContent = '';
      Object.keys(this.recoveryCodes).forEach((key) => {
        this.hiddenShortcutContent += `${this.recoveryCodes[key]}\n\n`;
      });
      this.copyShortcut(this.getHiddenShortcutContent);
    },
    copyShortcut(content) {
      const testingCodeToCopy = document.querySelector('#hiddenShortcutContent');
      testingCodeToCopy.value = content;
      testingCodeToCopy.setAttribute('type', 'text');
      testingCodeToCopy.select();

      try {
        document.execCommand('copy');
      } catch (err) {
        console.log(err);
      }

      testingCodeToCopy.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style></style>
